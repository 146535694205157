<template lang="pug">
  v-container
    v-row
      v-col.pb-0(cols="12")
        create-stock
      v-col.pt-0(cols="12")
        table-stock(
          @edit="editStock"
          :stock-id.sync="stockUnitStockId"
        )
    v-dialog(
      v-model="editStockDialog"
      max-width="30vw"
      eager
    )
      v-card
        v-toolbar.mb-2(
          dense
          collapse
          color="purple darken-3"
          elevation="0"
          dark
        )
          span Edit
        v-container
          edit-stock(
            @close-dialog="editStockDialog = false"
            :stock-data.sync="stockEditData"
          )
    stock-unit(
      :stock-id.sync="stockUnitStockId"
    )
</template>
<script>

const editStockVars = () => ({
  editStockDialog: false,
  stockEditData: {},
})

export default {
  name: 'StockPage',
  components: {
    createStock: () => import('./Create'),
    tableStock: () => import('./Table'),
    editStock: () => import('./Edit'),
    stockUnit: () => import('./StockUnit'),
  },
  data: () => ({
    ...editStockVars(),
    stockUnitStockId: null, 
  }),
  methods: {
    editStock (item) {
      this.editStockDialog = true
      this.stockEditData = item
    },
  },
}
</script>
